<!--/**-->
<!--* 自动生成 vue列表代码【模块名：库存记录】-->
<!--* 生成日期：2024/04/25 15:40:42-->
<!--* 生成路径: src/pages/product_qty_record/ProductQtyRecordList.vue-->
<!--* 生成人：admin-->
<!--*/-->
<template>
  <div>
    <PageHeaderLayout>
      <div class='main-page-content'>

        <el-tabs v-model="searchCondition.change_type" type="border-card" @tab-click="handleTabsClick">
          <el-tab-pane :key="key" v-for="(item, key) in changeTypeOptions" :label="item.label" :name="item.value">
            <div class='table-search'>
              <el-form :model='searchCondition' inline size='mini'>
                <el-form-item label='仓库'>
                  <el-select v-model='searchCondition.wh_id' placeholder='全部' clearable>
                    <el-option :label='option.label' :value='option.value' :disabled="option.state" :key='key'
                               v-for='(option, key) in warehouseOptions'></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label='产品名称'>
                  <el-input v-model='searchCondition.title' placeholder='产品名称' clearable></el-input>
                </el-form-item>
                <el-form-item label='招商负责人'>
                  <el-input v-model='searchCondition.leader_name' placeholder='招商负责人' clearable></el-input>
                </el-form-item>
                <el-form-item label='出库对象' v-if="searchCondition.change_type==2">
                  <el-input v-model='searchCondition.obj_name' placeholder='出库对象' clearable></el-input>
                </el-form-item>
                <el-form-item label='库存变更时间'>
                  <el-date-picker
                      v-model='searchCondition.created_at'
                      type='daterange'
                      align='right'
                      unlink-panels
                      range-separator='至'
                      start-placeholder='开始日期'
                      end-placeholder='结束日期'
                      value-format='yyyy-MM-dd'
                      :picker-options='pickerOptions'>
                  </el-date-picker>
                </el-form-item>
                <el-form-item>
                  <el-button type='primary' icon='el-icon-search' :disabled='loading' :loading='loading'
                             @click='handleQuery'>搜索
                  </el-button>
                </el-form-item>
              </el-form>
            </div>
            <!--    渲染表格-->
            <div class='default-table'>
              <MlPageTable :all-columns='defaultColumns[item.code]'
                           :table-name='tableName[item.code]'
                           :data-list='dataList'
                           :limit.sync='pageData.page_size'
                           :page.sync='pageData.current_page'
                           :total.sync='total'
                           :height='height'
                           highlight-current-row
                           :row-class-name='tableRowClassName'
                           :cell-class-name='addCellClass'
                           @row-click='rowClickEvent'
                           @cell-click='showInput'
                           @getList='getList'>
              </MlPageTable>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <el-dialog :title='cNoFrom.info._change_type' :visible.sync='cNoFromOpen' center width='50%' append-to-body :close-on-click-modal='false'>
        <div>
          <el-row>
            <el-col :span="19"><label>单据号：<span>{{cNoFrom.info.c_no}}</span></label></el-col>
            <el-col :span="5"><label>日期：<span>{{cNoFrom.info.created_at}}</span></label></el-col>
          </el-row>
        </div>
        <div style="width: 95%;margin: 20px auto">

          <el-table
            :data="cNoFrom.list"
            show-summary
            :summary-method="getSummaries"
            border
            style="width: 100%"
          >
            <el-table-column
              type="index"
              width="50"
              label='序号'
              header-align="center"
              align="center" />
            <el-table-column
              prop="title"
              label="商品名称"
              min-width="200"
              header-align="center"
              align="center" />
            <el-table-column
              prop="spec_name"
              label="规格"
              min-width="80"
              header-align="center"
              align="center" />
            <el-table-column
              prop="_abs_qty"
              label="数量"
              min-width="60"
              header-align="center"
              align="right" />
            <el-table-column
              prop="remark"
              label="备注"
              min-width="120"
              header-align="center"
              align="center" />
          </el-table>
        </div>
        <div>
          <el-row>
            <el-col :span="12"><label>仓库：<span>{{cNoFrom.info._wh_name}}</span></label></el-col>
            <el-col :span="8"><label>操作设备：<span>{{cNoFrom.info.op_device}}</span></label></el-col>
            <el-col :span="4"><label>操作人：<span>{{cNoFrom.info.op_name}}</span></label></el-col>
          </el-row>
        </div>
      </el-dialog>
    </PageHeaderLayout>
  </div>
</template>

<script>
import MlPageTable from '@/components/common/MlPageTable'
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ExportBtn from '@/components/export/ExportBtn'
import ImportProductQtyRecord from './ImportProductQtyRecord'

export default {
  name: 'ProductQtyRecordList',
  components: { ImportProductQtyRecord, ExportBtn, PageHeaderLayout, MlPageTable },
  data() {
    return {
      tableName: { in: 'qty_record_in', out: 'qty_record_out', check: 'qty_record_check' },
      loading: false,
      loadingCommit: false,
      dataList: [],
      total: 0,
      height: 800,
      searchCondition: {
        date_range: [],
        wh_id: null,
        title: null,
        change_type: null,
        leader_name: null,
        obj_name: null
      },
      pageData: { current_page: 1, page_size: 20 },
      orderBy: { created_at: 'desc' },
      warehouseOptions: [],
      changeTypeOptions: [
        { label: '入库', value: '1', code: 'in' },
        { label: '出库', value: '2', code: 'out' },
        { label: '盘点', value: '3', code: 'check' }
      ],
      pickerOptions: {
        shortcuts: [{
          text: '本月',
          onClick(picker) {
            picker.$emit('pick', [new Date(), new Date()])
          }
        }, {
          text: '今年至今',
          onClick(picker) {
            const end = new Date()
            const start = new Date(new Date().getFullYear(), 0)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近六个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setMonth(start.getMonth() - 6)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      defaultColumns: {
        in: [
          { hide: false, prop: '_change_type_state', label: '入库状态', width: 120, sortable: false, fixed: false },
          { hide: false, prop: 'c_no', label: '入库单号', width: 120, sortable: false, fixed: false },
          { hide: false, prop: 'title', label: '产品名称', width: 120, sortable: false, fixed: false },
          { hide: false, prop: '_wh_name', label: '仓库', width: 120, sortable: false, fixed: false },
          { hide: false, prop: 'whl_code', label: '库位', width: 120, sortable: false, fixed: false },
          { hide: false, prop: '_abs_qty', label: '入库数量', width: 120, sortable: false, fixed: false },
          { hide: false, prop: 'op_name', label: '操作人', width: 120, sortable: false, fixed: false },
          { hide: false, prop: 'created_at', label: '变更时间', width: 160, sortable: false, fixed: false },
          { hide: false, prop: 'remark', label: '备注', width: 120, sortable: false, fixed: false }
        ],
        out:[
          { hide: false, prop: '_change_type_state', label: '出库状态', width: 120, sortable: false, fixed: false },
          { hide: false, prop: 'c_no', label: '出库单号', width: 120, sortable: false, fixed: false },
          { hide: false, prop: 'title', label: '产品名称', width: 120, sortable: false, fixed: false },
          { hide: false, prop: '_wh_name', label: '仓库', width: 120, sortable: false, fixed: false },
          { hide: false, prop: 'whl_code', label: '库位', width: 120, sortable: false, fixed: false },
          { hide: false, prop: '_abs_qty', label: '出库数量', width: 120, sortable: false, fixed: false },
          { hide: false, prop: '_event_type', label: '对应事项', width: 120, sortable: false, fixed: false },
          { hide: false, prop: '_obj_name', label: '出库对象', width: 120, sortable: false, fixed: false },
          { hide: false, prop: 'op_name', label: '操作人', width: 120, sortable: false, fixed: false },
          { hide: false, prop: 'created_at', label: '变更时间', width: 160, sortable: false, fixed: false },
          { hide: false, prop: 'remark', label: '备注', width: 120, sortable: false, fixed: false }
        ],
        check:[
          { hide: false, prop: '_change_type_state', label: '盘点状态', width: 120, sortable: false, fixed: false },
          { hide: false, prop: 'c_no', label: '盘点单号', width: 120, sortable: false, fixed: false },
          { hide: false, prop: 'title', label: '产品名称', width: 120, sortable: false, fixed: false },
          { hide: false, prop: '_wh_name', label: '仓库', width: 120, sortable: false, fixed: false },
          { hide: false, prop: 'whl_code', label: '库位', width: 120, sortable: false, fixed: false },
          { hide: false, prop: '_abs_qty', label: '盘点数量', width: 120, sortable: false, fixed: false },
          { hide: false, prop: 'op_name', label: '操作人', width: 120, sortable: false, fixed: false },
          { hide: false, prop: 'created_at', label: '变更时间', width: 160, sortable: false, fixed: false },
          { hide: false, prop: 'remark', label: '备注', width: 120, sortable: false, fixed: false }
        ]
      },
      // 弹出层标题
      title: '添加/修改-库存记录',
      // 是否显示弹出层
      open: false,
      // 表单校验
      rules: {},
      // 表单参数
      form: {},
      // 单据参数
      cNoFromOpen: false,
      cNoFrom:{info:{}, list:[]},
      //是否显示弹出层:导入框
      openImport: false,
      //筛选条件缓存key
      pageSearchKey: 'page_search_key:product_qty_record'
    }
  },
  methods: {
    getSummaries(param) {
      // eslint-disable-next-line no-unused-vars
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index < 1) {
          return
        }
        if (index === 2) {
          sums[index] = '合计:'
          return
        }
        if (['_abs_qty'].indexOf(column.property) > -1) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev * 1 + curr * 1
              } else {
                return prev
              }
            }, 0)
            sums[index] += ''
          } else {
            sums[index] = 'N/A'
          }

        }
      })

      return sums
    },
    // eslint-disable-next-line no-unused-vars
    rowClickEvent(row, column, event) {
      // this.$notify.info(`数据ID：${row.id}`)
    },
    // eslint-disable-next-line no-unused-vars
    tableRowClassName({ row, rowIndex }) {
      return 'default-table-row'
    },
    addCellClass({ column }) {
      if (column && column.property === 'c_no') {
        return 'cell-link'
      }
    },
    handleSearchCondition() {
      let condition = {}
      Object.keys(this.searchCondition).forEach((field) => {
        if (field !== 'date_range' && this.searchCondition[field]) {
          condition[field] = this.searchCondition[field]
        }
      })

      if (this.searchCondition.date_range) {
        condition.date_range = this.searchCondition.date_range
      }

      // 分页 +排序
      Object.assign(condition, this.pageData, { orderBy: this.orderBy })

      return condition
    },
    handleQuery() {
      this.pageData.current_page = 1
      this.getList()
      //缓存筛选条件，刷新后可以继续使用
      sessionStorage.setItem(this.pageSearchKey, JSON.stringify(this.searchCondition))
    },
    async getList() {
      this.dataList = []
      this.loading = true
      let params = this.handleSearchCondition()
      let { list, pages } = await this.$api.getProductQtyRecordList(params)
      // this.dataList = [...this.dataList, ...list]
      this.dataList = list
      this.pageData.current_page = pages.current_page || 1
      this.pageData.page_size = pages.page_size || 100
      this.total = pages.total
      this.loading = false
    },
    /** 删除按钮 */
    handleDelete(row, index) {
      if (row.id) {
        //真实删除
        this.$confirm('此操作将删除该行数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.delRow(row.id)
          this.dataList.splice(index, 1)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      } else {
        this.tableData.splice(index, 1)
      }
    },
    delRow(id) {
      this.$api.deleteProductQtyRecord(id)
    },
    /** 修改按钮 */
    handleAdd() {
      this.open = true
      this.title = '添加-库存记录'
      // let { info } = await this.$api.getProductQtyRecordInfo(row.id)
      this.form = {}
    },
    /** 修改按钮 */
    async handleEdit(row) {
      this.open = true
      this.title = '修改-库存记录'
      // let { info } = await this.$api.getProductQtyRecordInfo(row.id)
      this.form = { ...row }
    },
    // eslint-disable-next-line no-unused-vars
    handleTabsClick(tab, event) {
      this.handleQuery()
    },

    /** 提交按钮 */
    async submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.loadingCommit = true
          this.$api.saveProductQtyRecord(this.form).then(id => {
            if (id) {
              if (!this.form.id)
                this.msgSuccess('新增成功')
              else
                this.msgSuccess('修改成功')

              this.form.id = id
              this.open = false
              this.getList()
            } else {
              this.msgError('操作失败')
            }
            setTimeout(() => {
              this.loadingCommit = false
            }, 500)
          })

        }
      })
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.form = {}
      this.cNoFromOpen = false
      this.cNoFrom = {info:{}, list:[]}
    },
    //导出表格
    async handleExport() {
      try {
        let searchCondition = this.handleSearchCondition()
        let response = await this.$api.exportProductQtyRecords(searchCondition)
        let name = `库存记录`

        this.$refs.refLogisticSubscribeExport.exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        // alert(JSON.stringify(e))
        this.$refs.refLogisticSubscribeExport.hideLoading()
      }
    },
    handleWarehouseOption() {
      this.$api.getWarehouseOption().then(data => {
        if (data.list.length > 0) {
          data.list.forEach(item => {
            this.warehouseOptions.push({ label: item.name, value: item.id, state: !item.state })
          })
        } else {
          this.warehouseOptions = []
        }
      })
    },
    showInput(row, column) {
      if (column.property === 'c_no') {
        this.cNoFromOpen = true
        if (row) {
          this.cNoFrom = {
            info:{
              c_no: row.c_no,
              _change_type: row._change_type + '单',
              created_at: row.created_at,
              _wh_name: row._wh_name,
              op_device: row.op_device,
              op_name: row.op_name,
            },
            list:[row],
          }
        }
      }
    }
  },
  created() {
    if (sessionStorage.getItem(this.pageSearchKey)) {
      this.searchCondition = JSON.parse(sessionStorage.getItem(this.pageSearchKey))
    }
    // 注意执行顺序, 先填充搜索条件
    this.handleWarehouseOption()
    this.searchCondition.change_type = '1'
    if (this.$route.query) {
      if (this.$route.query.wh_id !== undefined) this.searchCondition.wh_id = parseInt(this.$route.query.wh_id)
      if (this.$route.query.title !== undefined) this.searchCondition.title = this.$route.query.title
    }
    // 注意执行顺序, 再执行查询
    this.handleQuery()
  }
}
</script>

<style>
.cell-link {
  text-decoration-color: #ff3176;
  text-underline-position: under;
  text-decoration-line: underline;
}
</style>
<style scoped>

</style>
